import './AmountToPayTextField.css';
import { Component } from 'react';
import ContactFormSVG from './contact-form.svg';

class AmountToPayTextField extends Component {

    state = {
        isFocused: false
    }

    setActiveHandler = () => {
        this.setState({
            isFocused: true
        })
    };

    setUnactiveHandler = () => {
        this.props.onBlurHandler();
        this.setState({
            isFocused: false
        })
    }

    render() {

        let textFieldContainerClassNames = ['textFieldContainer'];

        if (this.state.isFocused) {
            textFieldContainerClassNames.push('focused');
        }

        if (!this.state.isFocused && this.props.errorState) {
            textFieldContainerClassNames.push('errorState');
        }

        return (
            <div className="AmountToPayTextFieldContainer">
                <div className="textFieldAndDateTextContainer">
                    <p className="enterAmountToPayText">Ingrese la Cantidad a Pagar</p>
                    <div className={textFieldContainerClassNames.join(' ')}>
                        <input className="AmountToPayTextField" type="text" inputMode="decimal" onFocus={this.setActiveHandler} onInput={(event) => this.props.textChanged(event)} onBlur={this.setUnactiveHandler} value={this.props.value} placeholder="$0.00"/>
                    </div>
                </div>
                <img className="contactForm" src={ContactFormSVG} alt="" />
            </div>
        );
    }

}

export default AmountToPayTextField;