import { Component } from 'react';
import ModalContainer from '../ModalContainer/ModalContainer.js';
import GeneralActionButton from '../GeneralActionButton/GeneralActionButton.js';
import './PaymentConfirmationModal.css';
import ContactFormSVG from '../AmounToPayTextField/contact-form.svg';
import Checkmark from './checkmark.svg';

class PaymentConfirmationModal extends Component {

    convertTimeFromUnixToDate = () => {
        const a = new Date(this.props.paymentDate * 1000);
        const months = ['January','February','March','April','May','June','July','August','September','October','November','December'];
        const year = a.getFullYear();
        const month = months[a.getMonth()];
        const date = a.getDate();
        return month + ", " + date + " " + year;
      }

    formatAmountPaid() {
        if (this.props.amountPaid.length > 2) {
            return this.props.amountPaid.substring(0, this.props.amountPaid.length - 2) + "." + this.props.amountPaid.substring(this.props.amountPaid.length - 2); 
        } else {
            return ""
        }
    }

    render() {
        return (
            <ModalContainer>
                <div className="svgContainer">
                    <img className="paymentConfirmationContactForm" src={ContactFormSVG} alt="" />
                    <img className="checkoutCheckmark" src={Checkmark} alt="" />
                </div>
                <div className="containerFooter">
                    <div className="invoicePaidText">Factura Pagada</div>
                    <div className="amountPaidText">${this.formatAmountPaid()}</div>

                    <div className="flexContainers">
                    <div className="flexContainer">
                        <div className="firstColumnText">
                            Código de Confirmación
                        </div>
                        <div className="secondColumnText">
                            {this.props.confirmationCode}
                        </div>
                    </div>
                   
                    </div>
                    <div className="thankYouText">Gracias por Tu Pago</div>
                </div>
            </ModalContainer>
        )
    }
}

export default PaymentConfirmationModal;